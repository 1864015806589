import React, { useEffect } from "react";

import TopHeader from "./TopHeader";

import AOS from "aos";
import "aos/dist/aos.css";
export const HomeInformation = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <TopHeader />
      <div className="overflow-x-hidden md:mb-8">
        <div
          className="flex flex-col  work-in-progress-banner-section py-2 my-4 items-center  fade-in-down px-6"
          data-aos="fade-right"
          data-aos-duration="1500"
        >
          <img
            src="/assets/images/wipNew.jpg"
            //   className="object-cover h-full w-full"
            className="w-full md:w-auto md:ml-[30px] md:h-[106px] fade-in-down"
          />
        </div>

        <div className="flex flex-col items-center py-14  bg-gray-100 fade-in-down px-6">
          <h1
            className="text-lg font-normal container mx-auto text-center"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <div>
              <p className="font-bold text-2xl ">
                Prabalta.org will be the Non-profit organization that provides
                IT infrastructure and Payment Platform to other non profits.
              </p>
            </div>
            {/* <br />
          <div>
            <p className="font-bold text-2xl">
              What does IT infrastructure mean?
            </p> 
          </div>*/}
            <br /> Prabalta.org is a non-profit organization dedicated to
            supporting other non-profits by providing essential IT
            infrastructure and a secure payment platform. Our comprehensive
            suite of services includes email communication, subdomain creation,
            social media integration, donor management system, Analytics and
            Reporting etc. We empower organizations with the technological
            backbone needed to efficiently operate and deliver their services.
            Through our services, we enable non-profits to focus on their
            missions while ensuring they have reliable tools to manage their
            operations and facilitate online transactions securely. Prabalta.org
            strives to foster a strong and interconnected non-profit community
            by leveraging technology for positive social impact.
          </h1>
        </div>

        <div>
          <div className="container mx-auto pb-10 mt-8">
            <div className="text-black text-3xl font-bold text-center">
              Donor Management Service.
            </div>
          </div>
        </div>

        <div className="m-0 md:flex  service-boxes">
          <div className="md:w-3/4 bg-gray-100 md:p-20 p-10 flex flex-col justify-center borderLeft md:ml-[10px]">
            <h1 className="text-2xl font-bold">Features:</h1>

            <p className="mt-4 md:w-[100%] list-items ps-6 mb-4">
              Maintain a comprehensive database of donors including their
              contact information, donation history, communication preferences,
              and other relevant details.
            </p>
            <p className="mt-4 md:w-[100%] list-items ps-6 mb-4">
              Track donations in real-time, including online donations and
              recurring donations . Streamline donation processing by providing
              payment options, automated receipt generation, and integration
              with payment gateways.
            </p>
            <p className="mt-4 md:w-[100%] list-items ps-6 mb-4">
              Send personalized thank-you messages, acknowledgments, and updates
              to donors to foster relationships and encourage continued support.
            </p>

            <p className="mt-4 md:w-[100%] list-items ps-6 mb-4">
              Provide customizable reports and analytics to understand donor
              behavior, campaign performance, and fundraising trends. We track
              key metrics like donor retention rate and fundraising
              effectiveness to guide strategic decisions and maximize ROI.
            </p>
          </div>
          <div
            className="md:w-1/2"
            data-aos="fade-left"
            data-aos-duration="1500"
          >
            <img
              src="/assets/images/hww-vector-2.png"
              className="w-full md:w-auto md:ml-[30px] md:h-[400px]"
            />
          </div>
        </div>

        <div>
          <div className="container mx-auto pb-10 mt-8">
            <div className="text-black text-3xl font-bold text-center">
              Social media Integration.
            </div>
          </div>
        </div>

        <div className="m-0 md:flex service-boxes ">
          <div
            className="w-1/2 hidden md:block"
            data-aos="fade-right"
            data-aos-duration="1500"
          >
            <img
              src="/assets/images/socialMedia2.jpg"
              // className="object-cover h-[500px] w-full"

              className="w-full md:w-auto md:h-[500px] "
            />
          </div>
          <div
            className="md:w-3/4 bg-gray-100 md:p-20 p-10 flex flex-col justify-center borderLeft md:me-[13px]"
            //   data-aos="fade-left"
            //   data-aos-duration="1000"
          >
            <h1 className="text-2xl font-bold">Features:</h1>

            {/* <ul className="flex flex-col  pl-6 infoList"> */}
            <p className="mt-4 md:w-[100%] list-items ps-6 mb-4">
              Shareable donation links: Allow donors to easily share donation
              links via social media platforms, amplifying fundraising efforts
              and reaching a wider audience.
            </p>
            <p className="mt-4 md:w-[100%] list-items ps-6 mb-4">
              Social login: Enable supporters to log in or sign up using their
              social media accounts, streamlining the donation process and
              enhancing user experience.
            </p>
            <p className="mt-4 md:w-[100%] list-items ps-6 mb-4">
              Social media tracking: Integrate analytics to track the
              effectiveness of social media campaigns, including click-through
              rates, engagement metrics, and conversions, to optimize future
              fundraising strategies.
            </p>

            <p className="mt-4 md:w-[100%] list-items ps-6 mb-4">
              Social media fundraising events: Facilitate the creation and
              promotion of fundraising events directly on social media
              platforms, leveraging their reach and engagement tools to attract
              participants and donors.
            </p>
            {/* </ul> */}
          </div>

          <div className="md:w-1/2 md:hidden block">
            <img
              src="/assets/images/socialMedia2.jpg"
              className="object-cover h-full w-full"
              data-aos="fade-right"
              data-aos-duration="1000"
            />
          </div>
        </div>

        <div>
          <div className="container mx-auto pb-10 mt-8">
            <div className="text-black text-3xl font-bold text-center">
              Analytics and Reporting
            </div>
          </div>
        </div>

        <div className="m-0 md:flex md:mb-8  service-boxes">
          <div className="md:w-3/4 bg-gray-100 md:p-20 p-10 flex flex-col justify-center borderLeft md:ml-[10px]">
            <h1 className="text-2xl font-bold">Features:</h1>

            <p className="mt-4 md:w-[100%] list-items ps-6 mb-4">
              Customizable reports: Generate detailed reports tailored to
              specific metrics, such as donor behavior, fundraising
              effectiveness, and campaign performance, allowing for in-depth
              analysis and insights.
            </p>
            <p className="mt-4 md:w-[100%] list-items ps-6 mb-4">
              Visual analytics: Utilize visualizations such as charts, graphs,
              and dashboards to present data in a clear and concise manner.
            </p>
            <p className="mt-4 md:w-[100%] list-items ps-6 mb-4">
              Comparative analysis: Conduct comparative analysis over time
              periods, campaigns, or donor segments to identify trend and areas
              for improvement.
            </p>

            <p className="mt-4 md:w-[100%] list-items ps-6 mb-4">
              Export and sharing capabilities: Provide functionality to export
              reports in various formats (e.g., PDF, CSV).
            </p>
          </div>
          <div
            className="md:w-1/2"
            data-aos="fade-left"
            data-aos-duration="1500"
          >
            <img
              src="/assets/images/analytics.jpg"
              className="w-full md:w-auto md:ml-[100px] md:h-[450px]"
            />
          </div>
        </div>

        <div>
          <div className="container mx-auto pb-10 mt-8">
            <div className="text-black text-3xl font-bold text-center">
              Email campaign.
            </div>
          </div>
        </div>

        <div className="m-0 md:flex service-boxes ">
          <div
            className="w-1/2 hidden md:block"
            data-aos="fade-right"
            data-aos-duration="1500"
          >
            <img
              src="/assets/images/emailCamp.jpg"
              className="w-full md:w-auto md:h-[400px] md:mt-8 "
            />
          </div>
          <div
            className="md:w-3/4 bg-gray-100 md:p-20 p-10 flex flex-col justify-center borderLeft md:me-[13px]"
            //   data-aos="fade-left"
            //   data-aos-duration="1000"
          >
            <h1 className="text-2xl font-bold">Features:</h1>

            {/* <ul className="flex flex-col  pl-6 infoList"> */}
            <p className="mt-4 md:w-[100%] list-items ps-6 mb-4">
              Create customized emails with recipient names and relevant
              information to strengthen engagement, while keeping an eye on how
              many people open and click on your emails to see how well they're
              doing.
            </p>
            <p className="mt-4 md:w-[100%] list-items ps-6 mb-4">
              Divide your email list into specific groups based on demographics
              or behavior, delivering tailored content to each segment, and
              analyze delivery, open, and conversion rates to refine targeting
              strategies.
            </p>
            <p className="mt-4 md:w-[100%] list-items ps-6 mb-4">
              Streamline email campaigns through automated scheduling, tracking
              delivery status, and analyzing metrics such as bounce rates and
              click-to-open rates to optimize campaign performance.
            </p>

            <p className="mt-4 md:w-[100%] list-items ps-6 mb-4">
              Conduct A/B testing on email elements while monitoring delivery,
              open, and delivered metrics, enabling data-driven decisions to
              enhance email effectiveness and maximize engagement.
            </p>
            {/* </ul> */}
          </div>

          <div className="md:w-1/2 md:hidden block">
            <img
              src="/assets/images/emailCamp.jpg"
              className="object-cover h-full w-full"
              data-aos="fade-right"
              data-aos-duration="1000"
            />
          </div>
        </div>

        <div>
          <div className="container mx-auto pb-10 mt-8">
            <div className="text-black text-3xl font-bold text-center">
              Make your Own website (subDomain)
            </div>
          </div>
        </div>

        <div className="m-0 md:flex md:mb-8  service-boxes">
          <div className="md:w-3/4 bg-gray-100 md:p-20 p-10 flex flex-col justify-center borderLeft md:ml-[10px]">
            <h1 className="text-2xl font-bold">Features:</h1>
            {/* <ul className="flex flex-col  pl-6 infoList"> */}
            <p className="mt-4 md:w-[100%] list-items ps-6 mb-4">
              Enable users to easily create their own unique subdomains under
              your non-profit organization's main domain, allowing them to
              personalize their website URLs.
            </p>
            <p className="mt-4 md:w-[100%] list-items ps-6 mb-4">
              Provide an intuitive interface for users to set up and manage
              their subdomains effortlessly, without requiring technical
              expertise or coding knowledge.
            </p>
            <p className="mt-4 md:w-[100%] list-items ps-6 mb-4">
              Offer a selection of pre-designed website templates for users to
              choose from, helping them quickly launch their websites with
              professional-looking designs.
            </p>

            <p className="mt-4 md:w-[100%] list-items ps-6 mb-4">
              Enable users to upload and customize their logos and metadata,
              allowing them to maintain brand consistency and enhance their
              website's identity.
            </p>
            {/* </ul> */}
          </div>
          <div
            className="md:w-1/2"
            data-aos="fade-left"
            data-aos-duration="1500"
          >
            <img
              src="/assets/images/subDomain.jpg"
              className="w-full md:w-auto md:ml-[100px] md:h-[450px]"
            />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 md:flex primary-bg-color text-white p-2  md:justify-between items-center px-4 md:px-10 2xl:px-20 ">
        <div></div>
        <div className="flex items-center space-x-4 justify-end"></div>
      </div>
    </>
  );
};

export default HomeInformation;

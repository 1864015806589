import React from "react";
import { Spinner } from "@material-tailwind/react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TQ_URL } from "../../utility/baseUrl";
import { getRequestWithTokenWithoutData } from "../../utility/apiRequest";

const TopHeader = () => {
  const [userData, setUserData] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const isUserLoggedIn = () => {
    return userData !== null;
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const logout = () => {
    setIsLoading(true);

    setIsDropdownOpen(false); // Close the dropdown immediately

    setTimeout(async () => {
      localStorage.removeItem("userData");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userAddressDetails");

      try {
        const response = await getRequestWithTokenWithoutData(
          `${TQ_URL}/logoutOauth`
        );
      } catch (error) {}
      localStorage.setItem("loggedIn", false);
      setUserData(null);
      setIsLoading(false);
      navigate("/");
    }, 1000);
  };

  const logLocalData = () => {
    const localStorageData = localStorage.getItem("userData");
    console.log("Local Storage Data:", JSON.parse(localStorageData));
    setUserData(JSON.parse(localStorageData));
  };

  useEffect(() => {
    logLocalData();
  }, []);

  //const [firstName] = userData.userName ? userData.userName.split(' ') : [''];

  return (
    <>
      {/* Top Header */}
      <div className="grid grid-cols-2 md:flex primary-bg-color text-white p-2  md:justify-between items-center px-4 md:px-10 2xl:px-20 ">
        <div>
          {/* <span className="text-sm md:text-lg">info@prabalta.com</span> */}
          {/* <div className="mb-4 md:mb-0 flex justify-between w-full md:w-2/12 "> */}
          <img
            src="/assets/images/main-logo.svg"
            alt="Logo"
            className="h-10  mx-auto sm:mx-0"
          />
          {/* </div> */}
        </div>
        <div className="flex items-center space-x-4 justify-end"></div>
      </div>
    </>
  );
};

export default TopHeader;

import "./App.css";
import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useNavigate,
  HashRouter,
} from "react-router-dom";
import HomeInformation from "./Components/Home/HomeInformation";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomeInformation />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>

      {/* <Login /> */}
      {/* <ChangePassword /> */}
      {/* <CreateAccount /> */}
      {/* <CreateProfile /> */}
      {/* <AddAddressDetails /> */}
      {/* <ForgetPassword />  */}
      {/* <AddBankDetails /> */}
      {/* <VerifyAddress /> */}
      {/* <Success /> */}
    </>
  );
}

export default App;
